<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align" style="padding: 2rem">
                <div class="plant-dd" style="width: 100%; display: flex; margin: 1rem 0">
                    <div class="plant-dd">
                        <label for="regionId" class="form-label select-lable lable-text"> Region <span
                                class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="regionId"
                            v-model="selectedRegionId" @change="getPlantList()">
                            <option disabled selected value="0">Select Region</option>
                            <option v-for="region in regionList" v-bind:key="region.energyRegionId"
                                :value="region.energyRegionId">
                                {{ region.energyRegionName }}
                            </option>
                        </select>
                        <span style="color: red" v-if="showPlantConfigureMsg">
                            Please configure the Region in user profile.
                        </span>
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="plantId" class="form-label select-lable lable-text"> Plant <span
                                class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            v-model="selectedPlantId">
                            <option disabled selected value="0">Select Plant</option>
                            <option v-for="plant in plantList" v-bind:key="plant.energyPlantId"
                                :value="plant.energyPlantId">
                                {{ plant.energyPlantName }}
                            </option>
                        </select>
                        <span style="color: red" v-if="showPlantConfigureMsg">
                            Please configure the Plant in user profile.
                        </span>
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="catagory" class="form-label select-lable lable-text"> Energy Zero Loss Category <span
                                class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="catagory"
                            v-model="selectedCategoryId">
                            <option disabled selected value="0">Please Select</option>
                            <option v-for="wc in categoryList" v-bind:key="wc.catagoryId" :value="wc.catagoryId">
                                {{ wc.categoryName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="catagory" class="form-label select-lable lable-text"> Process Area <span
                                class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="processArea"
                            v-model="processAreaId">
                            <option disabled selected value="0">Please Select</option>
                            <option v-for="pa in processAreaList" v-bind:key="pa.processAreaId" :value="pa.processAreaId">
                                {{ pa.processAreaName }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="utility" class="form-label select-lable lable-text"> Process Area <span
                                class="require-cl">*</span></label>
                        <input class="form-control select-text select-text-pd input-f" v-model="processArea"
                            placeholder="Type Here" />
                    </div> -->
                </div>
                <div class="plant-dd" style="width: 100%; display: flex; margin: 1rem 0">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Potential Water Reduction
                            <span class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="potentialReduction"
                            v-model="potentialReduction">
                            <option disabled selected value="0">Please Select</option>
                            <option :value="1">Yes</option>
                            <option :value="2">No</option>
                        </select>
                    </div>
                    <div class="project-dd" style="margin-left: 2.6%">
                        <label for="plant" class="form-label select-lable lable-text"> Project Name <span
                                class="require-cl">*</span></label>
                        <input class="form-control select-text select-text-pd input-f" v-model="projectName"
                            placeholder="Type Here" />
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="period" class="form-label select-lable lable-text"> Category of Project Investment
                            <span class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="period"
                            v-model="selectedInvestmentCategoryId">
                            <option disabled selected value="0">Please Select</option>
                            <option v-for="wc in investmentCategoryList" v-bind:key="wc.categoryId"
                                :value="wc.categoryName">
                                {{ wc.categoryName }}
                            </option>
                        </select>
                        <span style="color: red" v-if="valdCategory"> Please Select </span>
                    </div>
                </div>
                <div class="plant-dd" style="width: 100%; display: flex; margin: 1rem 0">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Investment ($000) </label>
                        <input class="form-control select-text select-text-pd input-f" min="0" placeholder="Type Here"
                            v-model="investment" @input="changingValue()" pattern="^[0-9]"
                            @keydown="isValidFormat($event)" />
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="plant" class="form-label select-lable lable-text"> Cost Savings ($000) </label>
                        <input class="form-control select-text select-text-pd input-f" min="0" placeholder="Type Here"
                            v-model="costSaving" @input="changingValue()" pattern="^[0-9]" required
                            @keydown="isValidFormat($event)" />
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="period" class="form-label select-lable lable-text"> Payback (Years) </label>
                        <input class="form-control select-text select-text-pd input-f" min="0" placeholder="Type Here"
                            v-model="paybackCalculate" disabled @input="changingValue()" pattern="^[0-9]" title="Read Only"
                            @keydown="isValidFormat($event)" />
                    </div>
                </div>
            </div>
            <hr class="hr" />
            <div class="even-space-vertical-align" style="padding: 2rem">
                <div class="module-title default-font-color">Energy Reduction (BTU)</div>
                <div class="year-value-dd"
                    style="width: 100%; display: flex; margin: 1rem 0; justify-content: space-between">
                    <div v-for="yearwise in yearWiseValues" :key="yearwise.year" class="year-value-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> {{ yearwise.year }} </label>
                        <input class="form-control select-text select-text-pd input-f" min="0" placeholder="Type Here"
                            v-model="yearwise.value" @input="changingValue()" pattern="^[0-9]"
                            @keydown="isValidFormat($event)" />
                    </div>
                </div>
                <p style="font-size: 14px; color: orangered">
                    NOTE: BTU reductions for each project are allowed to be accounted for 12 months. Please allocate savings
                    starting from the time they are implemented, plus 12 months.
                    (i.e. if a project is started in July 2024, then half of the reduction should be allocated in 2024 and
                    half in 2025).
                </p>
            </div>
            <hr class="hr" />
            <div class="even-space-vertical-align" style="padding: 2rem">
                <div class="module-title default-font-color">Main Fuel Source for Project</div>
                <div class="plant-dd" style="width: 100%; display: flex; margin: 1rem 0">
                    <div class="plant-dd">
                        <label for="fuel-source" class="form-label select-lable lable-text"> Fuel Source <span
                                class="require-cl">*</span></label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="fuel-source"
                            v-model="selectedSource" @change="onChangeFuelSource($event)">
                            <option disabled selected value="0">Please Select</option>
                            <option v-for="wc in sourceList" v-bind:key="wc.projectSourceId" :value="wc.projectSourceId">
                                {{ wc.projectSourceName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="plant" class="form-label select-lable lable-text"
                            title="Potential KWH from Purchased or Generated Renewable Electricity.">
                            Potential KWH from Purchased..
                        </label>
                        <input class="form-control select-text select-text-pd input-f" min="0" placeholder="Type Here"
                            id="txtPotentialKWHT" v-model="potentialValue" @input="changingValue()" pattern="^[0-9]"
                            @keydown="isValidFormat($event)" :disabled="disablePotentialKWHTxtBox" />
                    </div>
                    <div class="plant-dd" style="margin-left: 2.6%">
                        <label for="period" class="form-label select-lable lable-text"> Year of Renewable Energy
                            implementation</label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="period"
                            v-model="selectedImplementationYear" :disabled="disablePotentialKWHTxtBox">
                            <option disabled selected value="0">Please Select</option>
                            <option v-for="wc in yearWiseValues" v-bind:key="wc.year" :value="wc.year">
                                {{ wc.year }}
                            </option>
                        </select>
                    </div>
                </div>
                <p style="font-size: 14px; color: orangered">
                    Note: Select primary fuel source impacting the energy savings project. The CO2 reduction will be
                    automatically calculated in Tableau based on the entered BTU savings and
                    fuel emissions factor. For projects related to renewable electricity procurement and generation, no BTU
                    savings will be reported in the Energy Reduction section, but you
                    should report the amount of KWH to be purchased or generated in the given year to calculate CO2 savings.
                </p>
            </div>

            <div class="even-space-vertical-align" style="padding: 2rem">
                <div class="btn-container" style="justify-content: space-between;">
                    <span style="font-size: large; color: red"
                        v-if="!(selectedRegionId && selectedPlantId && selectedCategoryId && potentialReduction && selectedInvestmentCategoryId && selectedSource && processAreaId && projectName)">
                        Marked (*) Please fill out all required information.
                    </span>
                    <div>
                        <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                            pill variant="outline-primary" @click="$router.go(-1)">
                            Cancel
                        </b-button>
                        <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem" pill variant="outline-primary"
                            @click="saveValue($event, !(selectedRegionId && selectedPlantId && selectedCategoryId && potentialReduction && selectedInvestmentCategoryId && selectedSource && processAreaId && projectName))">
                            Save
                        </b-button>
                    </div>
                    <!-- :disabled="!(selectedRegionId && selectedPlantId && selectedCategoryId
                            && potentialReduction && selectedInvestmentCategoryId && selectedSource && selectedImplementationYear
                            && processArea && projectName)" -->
                </div>
            </div>
        </div>
        <b-modal id="pillarAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true" size="sm"
            ref="pillarMsg" hide-footer hide-header-close :title="headText" :visible="showPopup">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    {{ successMsg }}
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="onClickOk()">
                    OK
                </b-button>
            </div>
        </b-modal>

        <b-modal id="validationAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true"
            ref="validationMsg" hide-footer hide-header-close :title="headTextForValid" :visible="showvalidationpopup">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    <span style="font-weight: bold; color: red">{{ validationMsg }}</span>
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="showvalidationpopup = false">
                    OK
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import "@/common/css/CommonStyle.css";
import "@/common/css/font-categories.css";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Loader from "@/components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "@/common/constants/api/index";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "EnergyLossProjectList",
    data() {
        return {
            showvalidationpopup: false,
            showLoader: true,
            showPopup: false,
            // processArea: "",
            processAreaId: 0,
            processAreaList: [],
            regionList: [],
            selectedRegionId: 0,
            plantList: [],
            selectedPlantId: 0,
            committedYr: new Date().getFullYear(),
            categoryList: [],
            // selectedCategoryId: 1,
            selectedCategoryId: 0,
            investment: 0,
            costSaving: 0,
            validationMsg: "",
            payback: 0,
            disablePotentialKWHTxtBox: true,
            investmentCategoryList: [
                {
                    categoryName: "No Cost/Low Cost ($0 - $10K)",
                    categoryId: 1
                },
                {
                    categoryName: "Medium Cost ($10 - $100K)",
                    categoryId: 2
                },
                {
                    categoryName: "High Cost (>$100K)",
                    categoryId: 3
                }
            ],
            selectedInvestmentCategoryId: 0,

            potentialReduction: 0,
            sourceList: [],
            selectedSource: 0,
            selectedImplementationYear: 0,
            potentialValue: 0,
            yearWiseValues: [
                {
                    year: new Date().getFullYear() + 1,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 2,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 3,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 4,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 5,
                    value: 0
                }
            ],
            projectName: "",
            headText: "Successfully Saved..!",
            headTextForValid: "Required fields are missing..!",
            successMsg: "",
            showPlantConfigureMsg: false,
            valdPotentialWaterReduction: false,
            valdCategory: false
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 5,
            subMenu: 2
        });
        this.$store.dispatch("breadcrumb", {
            title: "Energy Saving - Project Input",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Project Input", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getRegion();
        this.getCategory();
        this.getSource();
        this.getProcessAreaList();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected"]),
        paybackCalculate() {
            this.payback = this.costSaving !== 0 ? this.investment / this.costSaving : 0;
            if (this.payback === Infinity) return 0;
            return this.costSaving !== 0 ? Math.round((100 * this.investment) / this.costSaving) / 100 : 0;
        }
    },
    methods: {
        getProcessAreaList() {
            ApiCalls.httpCallDetails(Api.GETPROCESSAREALIST, "get").then((data) => {
                console.log(data);
                this.processAreaList = data.data;
                console.log("ProcessAreaList", this.processAreaList);

            });
        },
        isDecimal(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;

            if (charCode === 190) {
                return true;
            }

            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isValidFormat(e) {
            this.isDecimal(e);

        },
        changingValue() { },
        getRegion() {
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSREGIONS, "get").then((data) => {
                console.log("Region", data);
                let dataLength = data.data.length;
                if (dataLength !== 0) {
                    this.regionList = data.data;
                    this.getPlantList();
                } else {
                    this.showPlantConfigureMsg = true;
                }
            });
        },
        getPlantList() {
            this.showLoader = true;
            let plantPayload = {
                regionIds: [this.selectedRegionId],
                year: this.committedYr
            };
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSPLANTS, "post", plantPayload).then((data) => {
                console.log(data);
                this.plantList = data.data;
                this.selectedPlantId = 0;
                this.showLoader = false;
            });
        },
        getCategory() {
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSCATEGORY, "get").then((data) => {
                console.log(data);
                this.categoryList = data.data;
                this.selectedCategoryId = 0;
            });
        },
        getSource() {
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSSOURCE, "get").then((data) => {
                console.log(data);
                this.sourceList = data.data;

                this.selectedSource = 0;
                this.showLoader = false;
            });
        },
        saveValue(event, validation) {
            console.log("event", event);
            console.log("Eve", validation);
            if (validation !== true) {
                this.showLoader = true;
                this.investment = Number(this.investment);
                this.costSaving = Number(this.costSaving);
                const obj = {
                    zeroLossCategoryId: this.selectedCategoryId,

                    processArea: this.processAreaId.toString(),
                    plantId: this.selectedPlantId,
                    regionId: this.selectedRegionId,
                    potentialWaterReduction: this.potentialReduction == 2 ? false : true,
                    projectName: this.projectName,
                    projectInvestmentCategory: this.selectedInvestmentCategoryId,
                    investment: this.investment,
                    costSaving: this.costSaving,
                    payback: this.payback,
                    projectSourceId: this.selectedSource,
                    energyReductions: this.yearWiseValues.map((ele) => {
                        return {
                            year: ele.year,
                            value: Number(ele.value) ? Number(ele.value) : 0
                        };
                    }),
                    potentialValue: this.potentialValue,
                    selectedImplementationYear: this.selectedImplementationYear
                };
                ApiCalls.httpCallDetails(Api.SAVEENERGYLOSSPROJECTS, "post", obj).then((data) => {
                    console.log(data);
                    this.showLoader = false;
                    this.showPopup = true;
                    this.successMsg = "Project Input Data Successfully Saved.";
                });
            } else {
                this.showvalidationpopup = true;
                this.validationMsg = "Marked (*)  Please fill out all required information.";
                event.preventDefault();
            }
        },
        onClickOk() {
            this.showPopup = false;
            this.selectedRegionId = 0;
            this.selectedPlantId = 0;
            this.selectedCategoryId = 0;
            this.processAreaId = 0;
            this.potentialReduction = 0;
            this.projectName = "";
            this.selectedInvestmentCategoryId = 0;
            this.investment = 0;
            this.costSaving = 0;
            this.getPlantList();
            this.yearWiseValues = [
                {
                    year: new Date().getFullYear() + 1,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 2,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 3,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 4,
                    value: 0
                },
                {
                    year: new Date().getFullYear() + 5,
                    value: 0
                }
            ];
            this.potentialValue = 0;
            this.selectedSource = 0;
            this.selectedImplementationYear = 0;
        },
        onChangeFuelSource(evt) {
            let fuelSrcVal = evt.target.value;
            console.log("fuelSrcVal", fuelSrcVal);
            if (fuelSrcVal === "17" || fuelSrcVal === "18") {
                this.potentialValue = 0;
                this.disablePotentialKWHTxtBox = false;
            } else {
                console.log("this.disablePotentialKWHTxtBox", this.disablePotentialKWHTxtBox);
                this.potentialValue = 0;
                this.selectedImplementationYear = 0;
                this.disablePotentialKWHTxtBox = true;
            }
        }
    }
};
</script>
<style scoped>
[data-tip] {
    position: relative;
}

[data-tip]:before {
    /* hides the tooltip when not hovered */
    display: none;
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #1a1a1a;
    position: absolute;
    top: 30px;
    left: 35px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
}

[data-tip]:after {
    display: none;
    content: attr(data-tip);
    position: absolute;
    top: 35px;
    left: 0px;
    padding: 5px 8px;
    background: #1a1a1a;
    color: #fff;
    z-index: 9;
    font-size: 0.75em;
    height: 18px;
    line-height: 18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
    display: block;
}

.even-space-vertical-align {
    display: flex;
    flex-direction: column;
}

.plant-dd {
    width: 23%;
}

.year-value-dd {
    width: 18.5%;
}

.project-dd {
    width: 48.5%;
}

.btn-container {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btn {
    margin: 0 1rem;
    padding: 0 1.3rem;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    color: #274ba7 !important;
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
}

.hr {
    width: 97%;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef73 !important;
    opacity: 1;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .year-value-dd {
        width: 100% !important;
    }

    .project-dd {
        width: 100% !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
